import { createRouter } from 'vue-router'

const routes = [
  { name: 'Home', path: '/', component: () => import('../views/Home2.vue') },
  { name: 'Home2', path: '/2', component: () => import('../views/Home.vue') },
  { name: '404', path: '/404', component: () => import('../views/404.vue') },
  { name: 'Watch', path: '/watch/:slug', component: () => import( /* webpackChunkName: "watch" */ '../views/Watch.vue'), props: route => ({ params: route.params, query: route.query })},
  { name: 'WatchEmbed', path: '/watch/:slug/embed', component: () => import( /* webpackChunkName: "watch" */ '../views/WatchEmbed.vue'), props: route => ({ params: route.params, query: route.query })},
  { name: 'Record', path: '/record', component: () => import('../views/record.vue') },
  { path: '/features/video-creation', component: () => import('../views/features/video-creation.vue') },
  { path: '/case-study/wework-labs-increase-in-engagement', component: () => import('../views/case-study/wework-labs-increase-in-engagement.vue') },
  { path: '/case-study/huispedia-video-for-hr-and-sales', component: () => import('../views/case-study/huispedia-video-for-hr-and-sales.vue') },
  { path: '/case-study/getting-the-attention-of-in-high-demand-developers', component: () => import('../views/case-study/getting-the-attention-of-in-high-demand-developers.vue') },
  { path: '/use-case/marketing', component: () => import('../views/use-case/marketing.vue') },
  { path: '/use-case/sales', component: () => import('../views/use-case/sales.vue') },
  { path: '/features/video-hosting', component: () => import('../views/features/video-hosting.vue') },
  { path: '/features/video-analytics', component: () => import('../views/features/video-analytics.vue') },
  { path: '/pricing', component: () => import('../views/Pricing.vue') },
  { path: '/terms-and-conditions', component: () => import('../views/TermsConditions.vue') },
  { path: '/privacy-policy', component: () => import('../views/PrivacyPolicy.vue') },
  { path: '/video-for-saas-sales', component: () => import('../views/VideoForSaasSales.vue') },
  { path: '/contact', component: () => import('../views/Contact.vue') },
  { path: '/request-a-demo', component: () => import('../views/BookDemo.vue') },
  { path: '/learn', component: () => import('../views/learn/index.vue') },
  { path: '/integrations/hubspot', component: () => import('../views/integrations/hubspot.vue') },
  { name:'Article', path: '/learn/:slug', component: () => import('../views/learn/_slug.vue') },
  { path: '/meeting-confirmation', component: () => import('../views/Meeting-Confirmation.vue') },
  { path: '/data-security', component: () => import('../views/Security.vue') },
  { path: '/terms-of-service', component: () => import('../views/TermsOfService.vue') },
  
]

export default function (history) {
  return createRouter({
    history,
    routes
  })
}
import { createApp } from 'vue'
import { createWebHistory } from 'vue-router'
import createRouter from './router/index.js'
import App from './App.vue'
import { createHead } from '@unhead/vue'
import VueCookies from 'vue3-cookies'


// ...

const app = createApp(App)

const router = createRouter(createWebHistory())
const head = createHead()

app.use(router)
app.use(head)
app.use(VueCookies)
router.isReady().then(() => {
    app.mount('#app')
  })
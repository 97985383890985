<template>
  <Navbar v-if="$route.name != 'Watch' && $route.name != 'WatchEmbed' && $route.name != 'WatchEmbed404' && $route.name != 'Preview'" />
  <router-view/>
<div v-if="$route.name != 'WatchEmbed' && $route.name != 'WatchEmbed404' && $route.name != 'Preview'" v-show="!cookieAccept" class="container-fluid position-fixed bg-white pt-2 pb-4 text-secondary border-radius-25" style="background-color: white !important;
    left: 10px;
    width: 80% !important;
    max-width: 500px;bottom:10px;z-index:10001;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
      <div class="row">
        <div @click="acceptCookies()" class="col-12 my-auto text-right"><i class="fas fa-times"></i></div>
      </div>
      <div class="row">
        <div class="col-md-12 my-auto">
          <div class="row">
            <div class="col-8 my-auto">
              <p class="m-0">This website uses cookies to better understand how videos are viewed, and to improve your experience. <a style="text-decoration:underline;" to="/terms-and-conditions">Learn more</a></p>
            </div>
            <div class="col-4 my-auto text-center">
              <button @click="acceptCookies()" class="btn btn-secondary border-primary bg-white text-secondary">Got it</button>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <Footer v-if="$route.name != 'Watch' && $route.name != 'WatchEmbed' && $route.name != 'WatchEmbed404' && $route.name != 'Preview'" />
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import { useHead } from '@vueuse/head'
export default {
  name: 'App',
  setup() {
    useHead({
      // Can be static or computed
      title: 'Belel - Video Tool For Sales',
      meta: [
        {
          name: `description`,
          content: 'The easiest way to create engaging video messages. Video hosting software that improves the way you sell your service. Nurture customers relationships and close more deals with videos.',
        },
      ],
    })
  },
  data(){
    return {
      cookieAccept:null,
    }
  },
  components:{
    Navbar,
    Footer
  },
  mounted(){
    this.cookieAccept = this.$cookies.get('cookieAccept')
    if (!this.$cookies.get('bllsession')){
      this.$cookies.set('bllsession', this.makeid(20), '30d', '/')
    }
  },
  methods:{
    acceptCookies(){
      this.$cookies.set('cookieAccept', true, '30d', '/')
    this.cookieAccept = true
    },
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
          }
    return result;
  }
    
  },
}
</script>

<style>


.p .li {
  letter-spacing: 0.24px;
}
.font-weight-semibold{
  font-weight: 600 !important;
}
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

video::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

video::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

video::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls-overlay-play-button {
  display: none !important;
  -webkit-appearance: none;
}
.p-14{
  font-size: 14px;
}
.p-12{
  font-size: 12px;
}
.hbig{
  font-size: 58px;
}
.p-20{
  font-size: 20px;
}
.blogLink{
  color: #ff4952;
  text-decoration: underline !important;
}
.btn-outline-belel{
  border: 1px solid #ff4952;
}
.border-end-0 {
    border-right: 0!important;
}
.border-start-0 {
    border-left: 0!important;
}
.navbar-features li{
  margin-left: 4px;
  margin-right: 4px;
}
.navbar-features .active{
  border-bottom: 3px solid #ff4952;
}
.section h2{
  font-size:34px;
}
.border-radius-5{
  border-radius: 5px !important;
}
.border-radius-25{
  border-radius: 25px !important;
}
@media (max-width: 991px) {
  .border-radius-5{
  border-radius: 0px !important;
}
}
.process-steps{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.process-steps .step-wrapper{
  width: 80px;
  height: 80px;
  border-radius: 13px;
  vertical-align: -webkit-baseline-middle;
  text-align: center;
}
.process-steps .step-path-wrapper{
  height:70px;
  width: 80px;
  overflow: hidden;
}
.process-steps .step-path-wrapper .line-path{
 width: 100%;
 display: flex;
 position: relative;
 top:-93px;
 -webkit-animation: step-path 17s linear 0s infinite; /* Chrome, Safari, Opera */
    animation: step-path 17s linear 0s infinite;
}
.process-steps .step-path-wrapper .line-path2{
 width: 100%;
 display: flex;
 position: relative;
 top:-93px;
 -webkit-animation: step-path2 17s linear 0s infinite; /* Chrome, Safari, Opera */
    animation: step-path2 17s linear 0s infinite;
}
@keyframes step-path {
  0%   {top:-93px;}
  20%  {top: 0px;}
  100% {top: 0px;}
}
@keyframes step-path2 {
  0%   {top:-93px;}
  30%  {top: -93px;}
  60%  {top: -0px;}
  100% {top: 0px;}
}
.solutions .card:hover{
  margin-top: -20px;
  transition:margin-top 1s;
}
.gradient-blue{
  background-color:#E8F0F8;
}
.card-body.selected{
  border-radius: 25px;
  background-color: #ffffff;
}
.cases .card{
  box-shadow: 0 0.5rem 2.0rem rgba(0,0,0,.158)!important;
}
.gradient-grey{
  background: rgb(29, 24, 54);
  background: linear-gradient( 
    -20deg, rgb(29 24 54), rgb(0 57 78));
    
}
.radius-bottom{
  border-bottom-left-radius: 48% 13%;
  border-bottom-right-radius: 48% 13%;
}
.radius-top{
  border-top-left-radius: 48% 13%;
  border-top-right-radius: 48% 13%;
}

.bg-belel-primary{
  background: linear-gradient( 
45deg, rgb(23 43 201 / 87%) 0%, rgb(142 122 253) 100%);
}
.bg-blu-gradient{
  background: linear-gradient( 
  45deg, rgb(19 35 62 / 87%) 0%, rgb(6 49 72) 100%);
}
.bg-grey{
  background:#EAF0F6;
}
.bg-belel-light{
  background-color: #ffcebf !important;
}
.bg-belel-dark{
  background: linear-gradient( 
45deg, rgb(7 20 118 / 87%) 0%, rgb(43 24 147) 100%);
}
.bg-belel-lighter{
  background-color: #FFF7E9 !important;
}
.text-start {
    text-align: left!important;
}
.text-end {
    text-align: right!important;
}
.header-title{
  font-size: 35px;
  font-weight: bold;
}
.header-description{
  border-left: 4px solid #ff4952;
  padding-left: 15px;
}
.br-b-belel{
  border-bottom: 1px solid #FCCD82;
}
.dropdown-menu{
      border: 0;
      border-radius: 0px 0px 10px 10px;
      
    padding: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 30px 0px;
}
.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.195em;
    content: "";
    border: solid #343a40;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(
45deg
);
    -webkit-transform: rotate(
45deg
);
}
.section-title{
   border-left: 2px solid #FFAB24;
  padding-left: 15px;
}
.section-description{
  font-size: 18px;
}
.text-belel-primary{
    color: #ff5722;
}
.brand-img{
  height: 50px;
}
.nav-watch{
  position: relative !important;
  background-color: #ffffff !important;
}
.home-section h2 {
  font-weight: 500;
  font-size: 50px;
  max-width: 500px;
}
h3.quote{
  color: #3079ff;
  line-height: 1.5em;
  font-weight: 400;
  font-size: 35px;
  margin-top: 2em;
  margin-bottom: 2em;
}
.proTip{
  background-color: #9dcdff;
  border: 1px solid #62afff;
  padding: 26px;
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}
.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.logo_auth{
  position: absolute;
  top: 50%;
  left: 1.0625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: .875rem;
  height: .875rem;
}
.w-100 img{
  width:100%;
}
.navbar-brand img {
  height: 24px;
}
.nav-link{
  color:#514D66 !important;
  font-size: 15px;
}
.video-relative {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}
.belelOpt{
  position: sticky;
  top:25px;
}
.card.belelOpt ul{
  margin-bottom: 0;
  list-style: none;
  position: relative;
  padding: 0;
}
.card.belelOpt ul::before{
    position: absolute;
    content: "";
    left: -5px;
    background: #fff;
    height: calc(100% - 20px);
    left: 4px;
    width: 3px;
    top: 20px;
}
.card.belelOpt ul li:before {
    position: absolute;
    content: "";
    background: #fff;
    height: 20px;
    width: 20px;
    left: -5px;
    top: 10px;
    border-radius: 50%;
}
.card.belelOpt ul li {
    position: relative;
    padding: 5px 0 5px 35px;
    font-size: 15px;
    line-height: 2.2;
}
.bg-list-quality{
  background-color:rgba(0, 0, 0, 0.8) !important;
}
.list-quality{
  position: absolute;
  bottom: 62px;
}
#terms h1, #terms h2{
  font-size: 20px;
}
@-webkit-keyframes opacityControls {
  0%   { opacity: 1; }
  70%	  { opacity: 1; }
  100% { opacity: 0; }
}
@-moz-keyframes opacityControls {
  0%   { opacity: 1; }
  70%	  { opacity: 1; }
  100% { opacity: 0; }
}
@-o-keyframes opacityControls {
  0%   { opacity: 1; }
  70%	  { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes opacityControls {
  0%   { opacity: 1; }
  70%	  { opacity: 1; }
  100% { opacity: 0; }
}
.hoveringVideo{
  -webkit-animation: opacityControls 1s forwards; /* Safari 4+ */
  -moz-animation:    opacityControls 1s forwards; /* Fx 5+ */
  -o-animation:      opacityControls 1s forwards; /* Opera 12+ */
  animation:         opacityControls 1s forwards; /* IE 10+, Fx 29+ */
}
.draggable-container {
  -webkit-transition: all 2s ease 0s;
  -moz-transition: all 2s ease 0s;
  -o-transition: all 2s ease 0s;
  -ms-transition: all 2s ease 0s;
  transition: all 2s ease 0s;
}
#draggable-container{
  position: absolute;
  z-index: 9;
  padding: 15px;
  padding-bottom:80px;
  height: 40%;
}
.draggable-large#draggable-container{
  left: 0;
  right: 0;
}
.draggable-large{
height:100% !important;
max-height: 100% !important;
transform: translate(0px, -7px) !important;
}

.video-draggable {
  position: absolute;
  z-index: 1;
  width: 20%;
  min-width: 200px;
}
.callDiv{
  background-color: rgb(1, 3, 28,0.59);
    z-index: 203;
    backdrop-filter: blur(9px);
}
.callDiv h2{
 font-size: 1.5em;
}
.video-interview {
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 100%;
  background-color: black;
}
.header-home {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 25px;
  padding: 30px 15px 90px 15px;
}
.heading-color{
  padding-bottom: 150px;
}
.opt-in-container {
  display: block;
  width: 396px;
  min-width: 65%;
  margin-left: auto;
  padding: 25px;
  margin-right: -15px;
  background-color: rgba(41, 48, 59, 0.9);
  text-align: left;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2em 0 0 2em;
}
.opt-in-form {
  max-width: 450px;
}
.opt-in-form .input-container {
  background-color: rgba(252, 252, 252, 0.1);
}
.opt-in-container h2 {
}
.opt-in-form input{
  background: transparent;
  border: 0;
  border-bottom: 1px solid #FFAC23;
  border-radius: 0;
  height: 50px;
  color: #ffffff;
}
.btn-white{
  background-color: #fff !important;
  border:0 !important;
}
.btn-belel {
  color: #fff;
  background-color: #ff5722;
  border-color: #ff5722;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
}
.btn-belel-outline {
  color: #fff;
  background-color: transparent;
  border-color: #ff5722;
  color: #ff5722;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
}
/* #f56d73 */
.btn-call {
  color: #fff;
  background-color: #ff5722;
  border:0;
  border-radius: 3px;
  letter-spacing: 0.03em;
  font-weight: 500;
}
.btn-preview{
  color: #fff;
  background-color: transparent;
  border:0;
  border-radius: 3px;
  letter-spacing: 0.03em;
  font-weight: 500;
  border:0;
}
.btn-call-secondary{
  color: #000000;
  background-color: #ffffff;
  border:0;
  border-radius: 3px;
  letter-spacing: 0.03em;
  font-weight: 400;
}
.btn-call-secondary:hover{
  color: #ff3d03;
}
.btn-demo{
  color: rgb(56, 89, 189);
  background-color: #fff;
  border:0;
  border-radius: 5px;
}
.btn-light {
  color: #FFAC23;
  background-color: #fff7e9;
  border-color: #fff7e9;
   border-radius: 5px;
}
.section{
  overflow: hidden;
}
.section img{
  width: 100%;
}
.section svg{
  width: 100%;
  max-height: 350px;
}
.content-article{
  font-size: 18px;
  line-height: 1.78;
}
.content-article h2{
  font-size: 30px;
  font-weight:bold;
  line-height: 1.78;
}
.content-article a{
  color: #ff4952;
}
.content-article a:hover{
  color: #cc151f;
  text-decoration: underline!important;
}
.b-dark-belel{
  background-color: #3c424d;
}
.most-popular {
    background-color: #ffd0c1;
    left: -3px;
    width: calc(100% + 6px);
    border: 3px solid #ffd0c1!important;
}
.rounded {
    border-radius: 0.375rem!important;
}
.rounded-start {
    border-bottom-left-radius: 0.375rem!important;
    border-top-left-radius: 0.375rem!important;
}
.rounded-end {
    border-top-right-radius: 0.375rem!important;
    border-bottom-right-radius: 0.375rem!important;
}
.feature-cases .card{
  overflow: hidden;
}
.feature-cases .card-image{
  width: 100%;
    overflow: hidden;
    height: 200px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-clip: border-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    min-width: 0;
    background-position: 50% 50%;
    background-size: cover;
}
.use-cases{
  margin-top: 50px;
  margin-bottom: 50px;
}
.use-cases small{
    font-size: 14px;
    line-height: 20px;
}
.use-cases .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.use-cases .card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: auto;
    height:100%;
    min-width: 0px;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 3px 3px 16px 0 rgba(98, 125, 152, 0.08), 0 -1px 8px 0 rgba(0, 0, 0, 0.04);
}
.use-cases .card .card-image {
    width: 100%;
    overflow: hidden;
    height: 200px;
    border-radius: 6px 6px 0 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-clip: border-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    min-width: 0;
    background-position: 50% 50%;
    background-size: cover;
}
.use-cases .card h2 {
  font-size: 1.4em;
  font-weight: 700;
}
.home-navbar {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  position:absolute;
  width:100%;
  border:0 !important;
  color:white;
}
.home-navbar .nav-link{
  color:white !important;
}
.home-navbar .dropdown-toggle::after{
    border: solid #ffffff !important;
    border-width: 0 2px 2px 0 !important;
}
.home-navbar .navbar-collapse.show{
    background-color: #02364C;
    padding: 15px;
}
.navbar-toggler {
  border: 0;
}
.tools-container img{
  max-width: 50px;
}
.tools-container h2{
 font-size: 1.2em;
}
.videoListView {
  min-height: 100vh;
  background-color: #f6f6f6;
}
html{
  height: -webkit-fill-available;
}
.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}
.main-container{
  background: #F9F9F9;
}

.navbar{
  z-index: 99;
}

.pdf-container {
  margin-top: 10px;
  display: flex;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  width: fit-content;
}
.icon-pdf-container {
  padding: 15px;
  border-radius: 4px;
}
.title-pdf-container{
  padding: 5px;
  display: flex;
}
.title-pdf-container .text-name{
  color:  #ff9397;
}
.title-pdf-container .text-size{
  font-size: 12px;
}
.buttons-pdf-container{
  display: flex;
}
.pdf-button{
  background:transparent;
  font-size: 14px;
  border: 0;
}
.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
    
    height: 100% !important;
}
.myVideo-dimensions.vjs-fluid {
    padding-top: 0px;
}
.video-container-record {
  max-height: 500px;
  height: 100%;
}
.videoListView .card {
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: none;
}
.videoListView .card-header-list{
  padding: 20px 20px 0;
  border-bottom: 0;
  background-color: transparent;
}
.videoListView .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.videoListView .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.videoListView .table-striped tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}
.videoListView .table-striped tbody > tr:nth-of-type(2n) {
  background-color: #fffaf9;
}
.videoListView .play-td a {
  margin-left: 15px;
  margin-right: 15px;
}

.black-light {
  color: #3c3b37;
}
.navbar-watch {
  padding: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.video-description{
  min-height: 150px;
}
.powered-by {
  position: fixed;
  z-index: 999;
  padding: 15px;
  background-color: #ffffff;
  border-top-right-radius: 5px !important;
  bottom: 0;
  font-size: 14px;
}
.text-belel {
  color: #ff5722;
}


.messages-header {
  padding-left: 15px;
  padding-right: 15px;
  height: 55px;
  border-bottom: 1px solid rgb(187, 187, 187);
  position: relative;
    z-index: 100;
    border-radius: 10px 10px 0px 0px;
}
.messages-header img{
  border-radius: 50%;
  max-width: 40px;
}
.messages-header .btn-add-message {
  margin-left: auto;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
}
.messages-header .fa-plus {
  font-size: 14px;
}
.messages-header .messages-header-title {
  font-size: 14px;
}
.full-img-view {
  position: absolute;
  z-index: 1000;
  padding: 2.5em;
  background-color: rgba(53, 53, 53, 0.5);
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.full-img-container {
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  max-height: 90%;
  padding: 10px;
  border-radius: 3px;
}
.full-img-container img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  border: 9px solid #ffffff;
  border-radius: 5px;
}
.full-upload-view {
  position: absolute;
  z-index: 1000;
  padding: 2.5em;
  background-color: rgba(39, 39, 39, 0.5);
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1rem;
}
.nav-dark{
  background-color: #29303b;
}
.navbar-brand img {
  height: 24px;
}

.powered-by-img {
  max-width: 35px;
  margin-bottom: 4px;
  margin-left: 5px;
}


.container-vid-info {
  z-index: 999;
  width: 100%;
  flex: 2;
  padding: 25px 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-vid-info h2 {
  font-size: 1.8em;
}
.container-vid-info video {
  pointer-events: none;
}
.message {
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}
.message h6{
  font-size: 0.9em;
  font-weight: 600;
}

.response {
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  text-align: right;
}
.response h6{
  font-size: 0.9em;
  font-weight: 600;
} 
.message .message-options {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  color: #adb5bd;
  margin-top: 0.3125rem;
}
.response .message-options {
      display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  color: #adb5bd;
  margin-top: 0.3125rem;
}
.message .message-content {
     padding: 0.6rem 1.25rem;
    color: #8094ae;
    border-radius: 0.55rem;
    text-align: left;
    display: inline-block;
    max-width: 25rem;
}
.message .message-content-preview {
      padding: 0.6rem 1.25rem;
    color: #8094ae;
    border-radius: 0.55rem;
    text-align: left;
    display: inline-block;
    max-width: 25rem;
}
.response .message-content {
  padding: 0.6rem 1.25rem;
  background-color: #ff4952;
  color: #ffffff;
  border-radius: 0.55rem;
  text-align: left;
  display: inline-block;
  max-width: 25rem;
}
.avatar {
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.open-image {
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  position: absolute;
  z-index: 990;
  right: -11px;
  bottom: -11px;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  height: 2.25rem;
  width: 2.25rem;
  min-width: 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(252, 252, 252, 0.781);
}
.close-image {
  float: right;
  min-width: 3rem;
  position: relative;
  z-index: 999;
  right: -11px;
  bottom: -11px;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  height: 2.25rem;
  width: 2.25rem;
  min-width: 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(252, 252, 252, 0.781);
  margin-bottom: -43px;
  margin-right: 22px;
}
.close-upload {
  float: right;
  min-width: 3rem;
  position: relative;
  z-index: 999;
  right: -11px;
  bottom: -11px;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  height: 2.25rem;
  width: 2.25rem;
  min-width: 2.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(252, 252, 252, 0.781);
  margin-bottom: -43px;
  margin-right: 22px;
}
.navbar-toggler {
  border: 0;
}
.avatar.avatar-sm {
  height: 2.25rem;
  width: 2.25rem;
  min-width: 2.25rem;
}
.message .avatar {
  display: inline-block;
  vertical-align: bottom;
  margin-top: -1.25rem;
}
.response .avatar {
  display: inline-block;
  vertical-align: bottom;
  margin-top: -1.25rem;
}
.message .message-options > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.response .message-options > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.message .message-options > *:not(.avatar, .online-icon) {
  height: 1.125rem;
  line-height: 1.125rem;
  display: inline-block;
  vertical-align: middle;
}
.avatar img,
.avatar span {
  vertical-align: middle;
  border-style: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message .avatar img {
  box-shadow: 0 0 0 0.2rem #fff;
}
.response .avatar img {
  box-shadow: 0 0 0 0.2rem #fff;
}
.typing {
  display: block;
  width: 60px;
  height: 40px;
  border-radius: 20px;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;
}
.circle.scaling {
  animation: typing 1000ms ease-in-out infinite;
  animation-delay: 3600ms;
}
.circle:nth-child(1) {
  animation-delay: 0ms;
}
.circle:nth-child(2) {
  animation-delay: 333ms;
}
.circle:nth-child(3) {
  animation-delay: 666ms;
}
@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
.background path {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin:-60px 0 0 -60px;
  animation: spin 15s 100;
  animation-direction: alternate;
}
.background path:nth-child(1) {
  animation-delay: 0ms;
}
.background path:nth-child(3) {
  animation-delay: 1000ms;
}
.background path:nth-child(2) {
  animation-delay: 4000ms;
}
@-moz-keyframes spin { 0% {
  transform: rotate(0);
}
100% {
  transform: rotate(10deg);
} }
@-webkit-keyframes spin { 0% {
  transform: rotate(0);
}
100% {
  transform: rotate(10deg);
} }
@keyframes spin { 0% {
  transform: rotate(0);
}
100% {
  transform: rotate(10deg);
} }
.controllers-container {
  position: relative;
  margin-top: -67px;
  z-index: 200;
}
.controllers-bar {
  background-image: -webkit-gradient(linear, center top, center bottom, from(rgba(0, 0, 0, 0.0)), to(rgb(0 0 0 / 71%)));
  min-height: 40px;
  bottom: 0;
}
.control-container {
  background-color: transparent;
  border: 0;
  height: 51px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
.video-duration{
  font-size:14px;
}
.time-container {
  background-color: #adb5bd;
  width: 100%;
  height: 13px;
  margin: 10px auto;
  position: relative;
}
.time-left {
  float: right;
  color: white;
  font-size: 10px;
}
.current-time-container {
  background-color: #ff4952;
  height: 100%;
  width: 0%;
}
.features-item:not(:last-child){
  border-bottom: 1px solid #8b8b8b;
}
.features-container{
  border: 1px solid #8b8b8b;
}
.feature-img-container{
  width: 30%;
}
.monthly-plan-container .btn-belel{
  width: 100%;
}
.overview-container{
  background-color: #f5f5f5;
}
.quantity-control-container *{
  border: 1px solid #51515151;
  padding: 10px;
}
.hide-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}
.no-resize {
  resize: none;
}
.chat-input {
  position: relative;
  width: 100%;
  min-height: 50px;
  border: 0px;
  padding: 5px;
}
.center-input {
  display: flex;
  align-items: center;
}
.button-chat {
  background-color: #ff4952;
  color: #ffffff;
  border: 3px solid white;
  border-radius: 50%;
}
.button-chat p{
  font-size: 20px;
  padding: 0.5em;
}
.video-container {
    position: relative;
    z-index: 4;
    background-color: #14171d;
    box-shadow: 0 2px 4px rgb(45 62 80 / 12%);
}
progress {
  width: 100%;
  border-radius: 0px; 
  height: 0px;
  box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
}
progress::-webkit-progress-bar {
  border-radius: 0px;
  height: 5px;
}
progress::-webkit-progress-value {
  background-color: rgb(255, 0, 85);
  border-radius: 0px 7px 7px 0px;
}
progress::-moz-progress-bar {
  /* style rules */
}
.message-background {
  min-height: 150px;
  width: 90%;
  background-size: cover;
  background-position: 50% 50%;
}
.chat-wrp{
    padding: 15px;
    max-width: 430px;
    height: 631px;
  }
.chat-body {
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  min-width: 0;
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border: 0;
  height: 100%;
  box-shadow: 0 2px 4px rgb(45 62 80 / 12%);
}

  #chatIcon:focus {
    outline: 0;
  }
  
  #chatIcon:before, #chatIcon:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  
  #chatIcon:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, #ff0081 20%, transparent 30%),
    radial-gradient(circle, #ff0081 20%, transparent 20%), 
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, #ff0081 15%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  #chatIcon:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, #ff0081 20%, transparent 20%), 
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, #ff0081 15%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%),
    radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
 
  #chatIcon:active{
    transform: scale(0.9);
    background-color: darken(#ff0081, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }
  
#chatIcon.animate:before{
      display: block;
      animation: topBubbles ease-in-out 0.75s ;
    }
#chatIcon.animate:after{
  display: block;
  animation: bottomBubbles ease-in-out 0.75s 	;
}
.newMessageBubble{
      position: absolute;
    background: #ff4c23;;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    bottom: 30px;
    display: none;
    font-size: 13px;
  -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  
}
.newMessageBubble.animate{
  display: flex;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.newMessageBubble p{
  font-size: 10px;
}
@keyframes fadeOutUp {
   0% {
      opacity: 0;
      transform: translate(0px);
   }
   100% {
      opacity: 1;
      transform: translate(-7px, -7px);
   }
} 

@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
 100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
 100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
.chat-message-create-container {
  position: relative;
  z-index: 5;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 2;
  min-height: 451px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 -7px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.chat-message-create-container small {
  float: right;
}
.input-group {
  max-width: 450px;
}
.input-group-append button {
  width: 175px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.containerMessage {
  overflow-y: scroll;
  padding-top: 59px;
  width: 100%;
  margin-bottom: 17px;
}
.containerMessage::-webkit-scrollbar {
  display: none;
}
.video-main-container {
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  min-width: 0;
  width: 100%;
  padding: 15px;
  background: #F9F9F9;
  overflow: scroll;
  max-width: 1035px;
}
.video-main-container-embed{
      display: flex;
    flex-grow: 1;
    flex-flow: column;
    min-width: 0;
    width: 100%;
    padding: 0;
    background: #141414;
    overflow: scroll;
    margin-top: auto;
    margin-bottom: auto;

}
.video-main-container::-webkit-scrollbar {
  display: none;
}
.video-main-container-embed::-webkit-scrollbar {
  display: none;
}
.chat-messages-container-preview{
  bottom:10px;
  right:30px;
  z-index:1001;
  max-height:40vh;
  display: flex;
  flex-direction: column-reverse;
  overflow-y:scroll;-webkit-mask-image: linear-gradient(to top, black 50%, transparent 100%);
  mask-image: linear-gradient(to top, black 50%, transparent 100%);
  max-height: 239px;
  width: 300px;
}
.chat-messages-container-preview::-webkit-scrollbar {
  display: none;
}
.chat-messages-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 2;
  position: relative;
    right: 0;
    left: 0;
    bottom: 65px;
    top: 0;
}

.chat-messages-container::-webkit-scrollbar {
  display: none;
}
.profile-info img{
  border-radius: 50%;
  width:30px;
  margin:4px;
}
.plans-list .currentPlan * {
  color: #29303b !important;
}
.plans-list .currentPlan img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.plans-list .currentPlan {
  border: 0 !important;
}
.plans-list .currentPlan h2 {
  font-weight: 300 !important;
}
.plans-list .currentPlan .btn-plans {
  background-color: transparent;
  border: 1px solid #515151;
  pointer-events: none;
}
.plans-list .card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: auto;
  height: 100%;
  min-width: 0px;
  margin-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 25px;
  background-color: #fff;
  border: 0;
}
.plans-list img {
  height: 40px;
}
.plans-list p {
  font-size: 0.8em;
}
.plans-list .active {
  border: 2px solid #ffac23;
}
.plans-list .active h2 {
  font-weight: 400;
}
.plans-list .card-header {
  font-size: 0.7em;
  background-color: #ffac23;
}
.plans-list .decimals{
  font-size: 14px;
  line-height: 49px;
}
.plans-list .curr-icon{
  font-size: 16px;
  line-height: 49px;
  margin-right: 0.2em;
}
.price {
    font-weight: 400;
    font-size: 30px!important;
}
.most-popular {
    background-color: #ffd0c1;
    left: -3px;
    width: calc(100% + 6px);
    border: 3px solid #ffd0c1!important;
}
.price-value{
  line-height: 55px;
}
.btn-plans {
  background-color: #ff5722;
  color: #ffffff;
  border: 1px solid #ff5722;
  padding: 0.3em 2em;
  font-size:20px;
  font-weight: bold;
  border-radius: 3px;
  width: 100%;
}
.btn-plans-secondary {
  background-color: transparent;
  color: #1f1f1f;
  border: 1px solid #1f1f1f;
  padding: 0.3em 2em;
  font-size:20px;
  font-weight: bold;
  border-radius: 3px;
  width: 100%;
}
.active .btn-plans {
  background-color: #ffac23;
  margin: 10px;
  width: 90%;
  color: #2c2c2c;
}
.plan-features-list {
    font-size: 14px;
    padding: 0;
    list-style: none;
}
.plan-features-list li {
  padding-left:0px;
  position: relative;
  margin-bottom: 10px;
}

.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 20px;
  background: #dfd9ea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}
.switch::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}
#checkChat:checked + .switch {
  background: #72da67;
}
#checkChat:checked + .switch::before {
  left: 27px;
  background: #fff;
}
#checkChat:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}
#checkScreen:checked + .switch {
  background: #72da67;
}
#checkScreen:checked + .switch::before {
  left: 27px;
  background: #fff;
}
#checkScreen:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}
.blog-post-meta{
  text-transform: uppercase;
}
.sendText {
  border-top: 1px solid #e5e9f2;
  padding-top: 1rem;
  padding-bottom: 1em;
  background:#ffffff;
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
}
.askForContact {
  background-color: #29303b;
  color: #ffffff;
}
/*Contact*/
.card-contact{
  background-color: #FFF7E9;
  min-height: 350px;
  padding: 15px;
}
.livechat_button a{
  color: #ffffff;
}
.video-home{
  transition: max-width 2s, width 2s, bottom 2s;
}
.home-animation{
  padding-bottom:30%;
}
@media (max-width: 830px) {
  .home-animation{
  padding-bottom:55%;
}
}
@media (max-width: 430px) {
  .home-animation{
  padding-bottom:70%;
}
}
.video-home.expand{
  max-width: 600px !important;
  width: 56% !important;
  bottom:20% !important;
}
@media (max-width: 430px) {
  .video-home.expand{
  bottom:40% !important;
  width: 80% !important;
}
}
.cursor-home{
  transition: right 2s, bottom 2s;
}
.cursor-home.position-1{
  bottom:20% !important;
  right:30% !important;
}
.cursor-home.position-2{
  bottom:0px !important;
  right:0px !important;
}
.cursor-home.position-3{
  bottom:10% !important;
  right:20% !important;
}
.fade-up {
  animation: fadeUp ease 1s;
  -webkit-animation: fadeUp ease 1s;
  -moz-animation: fadeUp ease 1s;
  -o-animation: fadeUp ease 1s;
  -ms-animation: fadeUp ease 1s;
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    margin-bottom: -100px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0px
  }
}

@-moz-keyframes fadeUp {
  0% {
    opacity: 0;
    margin-bottom: -100px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0px
  }
}

@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    margin-bottom: -100px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0px
  }
}

@-o-keyframes fadeUp {
  0% {
    opacity: 0;
    margin-bottom: -100px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0px
  }
}

@-ms-keyframes fadeUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading-card-title {
  height: 15px;
  width: 80%;
  background-color: #dddddd;
  border-radius: 25px;
}
.loading-card-description div {
  height: 6px;
  width: 80%;
  background-color: #e6e6e6;
  border-radius: 10px;
}
.loading-card-description .line-1 {
  width: 70%;
}
.loading-card-description .line-2 {
  width: 85%;
}
.loading-card {
  opacity: 0.8;
  background: linear-gradient(-45deg, #ededed, #fff, #fff, #ededed);
  background-size: 400% 400%;
  animation: gradient 1s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: -40% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 150% 100%;
  }
}

.pricing-table {
  border-top: 2px solid #EBF0F7;
}
.pricing-table tr {
  border-bottom: 2px solid #EBF0F7;
  border-right: 2px solid #EBF0F7;
  padding: 14px;
}
table td {
  border-left: 2px solid #EBF0F7;
  padding: 14px;
}
.pricing-table-prices{
  position:sticky;
  top:0;
  background-color: white;
  z-index:100;
  border-collapse: unset !important;
}
.pricing-table .labels{
  flex-grow: 1;
  flex-basis: 100px;
  align-self: start;
  position: sticky;
  background-color: white;
  top: -1px;
  z-index: 6;
  background:#fff;
}
.pricing-table td{
  position: relative;
  width:20%;
}

.pricing-table tr{
  position: relative;
}
.pricing-table tr td:nth-of-type(1){
  width: 285px;
  min-width: 285px;
}
.pricing-table-prices tr td:nth-of-type(1){
  width: 285px;
  min-width: 285px;
  padding:0px;
  
}
.pricing-table-prices tr td{
  padding:5px;
  border:0px;
  width:20%;
}
.pricing-table .fa-check{
  color: #4ce64c !important;
}
.online-icon{
  width:5px;
  height:5px;
  background-color:#33db00;
  border-radius:50%;
  margin:7px;
}
@media (min-width: 1200px) {
  
}

@media (max-width: 991px) {
  #topNavDesk{
    display: none;
  }
}

@media (min-width: 992px) {
  #topNavMob{
    display: none;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}
@keyframes reduce-video {
  0% {
    min-width: 300px;
    width: 100%;
    opacity: 1;
  }
  100% {
    min-width: 200px;
    width: 30%;
    opacity: 0.6;
  }
}

@-moz-keyframes reduce-video {
  0% {
    min-width: 300px;
    width: 100%;
    opacity: 1;
  }
  100% {
    min-width: 200px;
    width: 30%;
    opacity: 0.6;
  }
}

@-webkit-keyframes reduce-video {
  0% {
    min-width: 300px;
    width: 100%;
    opacity: 1;
  }
  100% {
    min-width: 200px;
    width: 30%;
    opacity: 0.6;
  }
}

@-o-keyframes reduce-video {
  0% {
    min-width: 300px;
    width: 100%;
    opacity: 1;
  }
  100% {
    min-width: 200px;
    width: 30%;
    opacity: 0.6;
  }
}

@-ms-keyframes reduce-video {
  0% {
    min-width: 300px;
    width: 100%;
    opacity: 1;
  }
  100% {
    min-width: 200px;
    width: 30%;
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
 
}
@media (max-width: 960px) {
  .reduceVideo #draggable-container{
    width: 100% !important;
  }
  #draggable-container{
  height: 69%;
  padding: 8px;
  padding-bottom: 70px;
}
  .videoListView .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  
  
  .chat-body {
  padding: 15px;
    padding: 0px;
  }
  .reduceVideo .controllers-container {
    position: relative;
    z-index: 100;
    margin-top: -67px;
    z-index: 200;
  }
  .reduceVideo .time-container {
    display: none;
  }
  .reduceVideo .spaceReduceVideo {
    display: block;
  }
  .reduceVideo {
    position: relative;
  }
  .reduceVideo .container-vid-info {
    position: relative;
    z-index: -1;
    animation: fadeOut forwards 1s;
    -webkit-animation: fadeOut forwards 1s;
    -moz-animation: fadeOut forwards 1s;
    -o-animation: fadeOut forwards 1s;
    -ms-animation: fadeOut forwards 1s;
  }
  .reduceVideo .powered-by {
    padding: 7px;
  }
  .reduceVideo .powered-by-img {
    max-width: 33px;
  }
  .reduceVideo .video-container {
    position: absolute;
    top: 53px;
    right: 0;
    animation: reduce-video forwards 1s;
    -webkit-animation: reduce-video forwards 1s;
    -moz-animation: reduce-video forwards 1s;
    -o-animation: reduce-video forwards 1s;
    -ms-animation: reduce-video forwards 1s;
  }
  .video-main-container{
    padding: 0px !important;
    background: #F9F9F9;
  }
  .chat-wrp{
    position: absolute;
    padding: 0px;
    height: calc(100vh - 57px);
  }
  .messages-header{
    border-radius:0px;
  }
.opt-in-container {
  max-width: 98%;
}
.opt-in-container h2 {
  font-size: 1.8em;
}
.header-home {
  margin: 15px;
}
#topNavDesk{
display: none;
}
.header h1, .header h2{
font-size: 30px;
line-height: 1em;
}
}
@media (min-width: 961px) {
  .chat-body{
    display: flex !important;
  }
  .messages-header{
    display: block !important;
  }
  #chatIcon{
    display: none !important;
  }
  #chatStart{
    display: block !important;
  }
  #expandControl{
    display: none !important;
  }
  .header h1, .header h2{
    font-size: 55px;
    line-height: 48px;
  }
  #chatStartNav{
    display: none;
  }
}



</style>
